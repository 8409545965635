import React, { FC, useMemo, useState } from 'react';
import { useGet } from '../../hooks/useApi';
import { projectApi } from '../../APIs/projectApi';
import { NotFound } from '../Errors';
import { Button } from '@aws-amplify/ui-react';
import { TaskLogs } from './TaskLogs';
import { Loading } from '../atomic/effects';
import { IconButton } from '../atomic/buttons';
import { difMinutes, reformat } from '../../utils/DateUtils';
import { useTenant } from '../../contexts/TenantContext';
import { downloadText } from '../../utils/download';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { TenantModel } from '../../models/TenantModel';
import { getTenantsForSuperAdmin } from '../../APIs/tenants';
import { TenantStorageStatus } from '../status/TenantStorageStatus';

type Prop = {};
export const ActivityLog: FC<Prop> = () => {
    const [month, setMonth] = useState(0);
    const { getTenantUser } = useTenant();

    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());

    const { start, end } = useMemo(() => {
        const now = new Date();
        const startDate = new Date(
            now.getFullYear(),
            now.getMonth() - month,
            1
        );
        const endDate = new Date(
            now.getFullYear(),
            now.getMonth() + 1 - month,
            1
        );

        return {
            start: startDate.toLocaleString(),
            end: endDate.toLocaleString(),
        };
    }, [month]);

    const getYearMonthString = (month: number) => {
        const now = new Date();
        const t = new Date(now.getFullYear(), now.getMonth() - month, 1);

        return `${t.getFullYear()}年${t.getMonth() + 1}月`;
    };
    const { isSuperAdmin } = useAuth();

    const [tenants, setTenants] = React.useState<
        Array<TenantModel> | undefined
    >(undefined);
    React.useEffect(() => {
        if (isSuperAdmin()) {
            getTenantsForSuperAdmin()
                .then((resp) => {
                    setTenants(resp.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, []);

    const download = () => {
        if (!tasks?.data?.length) {
            return;
        }
        const csvData = tasks?.data?.map((r) => {
            const user = getTenantUser(r.createdUserId);
            const userName = user
                ? `${user.familyName} ${user.givenName}`
                : 'unknown';
            const email = user ? `${user.email}` : 'unknown';
            const tenantName = () => {
                if (r.folder && r.folder.tenant) {
                    return r.folder.tenant.name;
                }
                const tenant =
                    tenants && tenants.length
                        ? tenants.find(
                              (tenant) => tenant.tenantId === user.tenantId
                          )
                        : undefined;

                return tenant ? tenant.name : 'unknown';
            };

            const row = [
                r.project ? r.project.name : '(削除されたプロジェクト)',
                r.project
                    ? r.project.aiAnalysisTypeNameJa
                    : '(削除されたプロジェクト)',
                r.folder ? r.folder.folderName : '(削除されたフォルダ)',
                r.status,
                reformat(r.createdAt, 'yyyy/MM/dd HH:mm'),
                reformat(r.updatedAt, 'yyyy/MM/dd HH:mm'),
                difMinutes(r.createdAt, r.updatedAt),
                r.aiRunTime ? Math.ceil(r.aiRunTime / 1000 / 60) : '',
                userName,
                email,
            ];
            if (isSuperAdmin()) {
                row.unshift(tenantName());
            }
            return row;
        });
        const header = [
            'プロジェクト名',
            'AI名',
            'フォルダ名',
            'ステータス',
            '作成日時',
            '更新日時',
            '実効時間(分)',
            '推論時間(分)',
            '実行ユーザー名',
            'メールアドレス',
        ];
        if (isSuperAdmin()) {
            header.unshift('テナント名');
        }
        csvData.unshift(header);
        const csvContent =
            '\ufeff' + csvData.map((row) => row.join(',')).join('\n');

        downloadText('task_log.csv', csvContent, 'text/csv');
    };

    const { data: tasks, error } = useGet(projectApi.get.tasks(start, end), {
        refreshInterval: 30000,
    });
    const now = new Date();

    if (!userInfo) return <Loading />;
    if (isSuperAdmin() && !tenants) {
        return <Loading />;
    }
    if (error) return <NotFound />;
    return (
        <div className="app-main log">
            <h2 className="header-name">プロジェクトログ</h2>
            <TenantStorageStatus />
            <div className="tab">
                <Button
                    className={month == 0 ? 'active' : 'disabled'}
                    onClick={() => setMonth(0)}
                >
                    {getYearMonthString(0)}
                </Button>
                <Button
                    className={month == 1 ? 'active' : 'disabled'}
                    onClick={() => setMonth(1)}
                >
                    {getYearMonthString(1)}
                </Button>
                <Button
                    className={month == 2 ? 'active' : 'disabled'}
                    onClick={() => setMonth(2)}
                >
                    {getYearMonthString(2)}
                </Button>
                {tasks?.data?.length ? (
                    <div className="download">
                        <IconButton
                            icon="fa-solid fa-download"
                            onClick={() => download()}
                        >
                            エクスポート
                        </IconButton>
                    </div>
                ) : (
                    ''
                )}
            </div>
            {tasks ? (
                <TaskLogs tenants={tenants} tasks={tasks?.data ?? null} />
            ) : (
                <Loading />
            )}
        </div>
    );
};
