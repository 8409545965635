import React, { FC } from 'react';
import classNames from 'classnames';
import { TenantModel } from '../../models/TenantModel';
import { User } from '../../contexts/TenantContext';

export const UserName = (props) => {
    const { className, user } = props;
    return (
        <span
            className={classNames('atomic atomic-text user-name', className, {
                unknown: !user,
            })}
        >
            {user ? `${user.familyName} ${user.givenName}` : 'unknown'}
        </span>
    );
};

export const UserMail = (props) => {
    const { className, user } = props;
    return (
        <span
            className={classNames('atomic atomic-text user-mail', className, {
                unknown: !user,
            })}
        >
            {user ? `${user.email}` : 'unknown'}
        </span>
    );
};

export type UserTenantNameProp = {
    user: User;
    tenants?: Array<TenantModel>;
};

export const UserTenantName: FC<UserTenantNameProp> = ({ user, tenants }) => {
    if (!user && !tenants) {
        return <>unknown</>;
    }

    const tenant =
        tenants && tenants.length
            ? tenants.find((tenant) => tenant.tenantId === user.tenantId)
            : undefined;

    return <>{tenant ? tenant.name : ''}</>;
};
