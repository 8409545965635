import {
    Axios,
    AxiosHeaders,
    AxiosRequestConfig,
    AxiosRequestTransformer,
    AxiosResponse,
    AxiosResponseTransformer,
} from 'axios';

import { API_BASE_URL } from '../config';
import useSWR, {
    MutatorOptions,
    SWRConfiguration,
    SWRResponse,
    mutate,
} from 'swr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProgressHandler } from '../utils/progress';
import { AuthHeader, getAuthHeader } from '../utils/getAuthHeader';
import { safeUrl } from '../utils/safeUrl';
import { checkAbort } from '../utils/abort';

export interface ApiOpts<Data> {
    axios?: AxiosRequestConfig;
    swr?: SWRConfiguration<Data>;
}

export class APIError extends Error {
    public data: any | null;
    public status: number | undefined;

    constructor(message: string, status?: number, data?: any) {
        super(message);
        this.data = data;
        this.status = status;
    }
}

const transformResponse: AxiosResponseTransformer = function (
    data,
    headers,
    status
) {
    if (status === undefined || status < 200 || status >= 300) {
        let err = data;
        let option = null;
        try {
            err = JSON.parse(data);
        } catch (err) {
            // エラーは JSON ではありません。
        }
        if (typeof err === 'object' && err !== null) {
            option = err?.data ?? null;
            if ('exception' in err) {
                console.log(err);
                err = err.exception;
            } else if ('error' in err) {
                console.log(err);
                err = err.error;
            } else if ('message' in err) {
                console.log(err);
                err = err.message;
            }
        }
        throw new APIError(String(err), status, option);
    }
    const contentType = headers['Content-Type'] ?? headers['content-type'];
    if (/[\+\/]json/i.test(contentType) && typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (err) {
            throw new Error(
                `Error while parsing response ${err} in data: ${data}`
            );
        }
    }
    return data;
};

const transformRequest: AxiosRequestTransformer = (data, headers) => {
    if (
        (headers['Content-Type'] ?? headers['content-type']) ===
            'application/json' &&
        data !== undefined
    ) {
        return JSON.stringify(data);
    }
    return data;
};

export const neverRevalidate: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
};
export interface APIGetRequest<Data> {
    data: any;
    key: string;
    get: (
        apis: InternalApis,
        key: string,
        config: GetAxiosConfiguration
    ) => Promise<Data>;
    swr?: SWRConfiguration<Data>;
    mutate: (data?: Data, opts?: MutatorOptions) => void;
}

export interface MutationResponse<Data> {
    status: number;
    data: Data;
}

type MutateHandler<Mutation extends APIMutateRequest<any, any>> = (
    apis: InternalApis,
    data: RequestOfMutation<Mutation>,
    options: MutationRequestOptions
) => Promise<MutationResponse<ResponseOfMutation<Mutation>>>;

export type APIMutateEffect<Request, Response> = (
    response: Response,
    request: Request,
    key: string | null
) => void | Promise<void>;

export interface APIMutateRequest<Request, Response> {
    mutate: MutateHandler<this>;
    key: string | null;
    get: APIGetRequest<Response> | null;
    effect: APIMutateEffect<Request, Response>;
}
export type RequestOfMutation<T extends APIMutateRequest<any, any>> =
    T extends APIMutateRequest<infer Request, any> ? Request : unknown;
export type ResponseOfMutation<T extends APIMutateRequest<any, any>> =
    T extends APIMutateRequest<any, infer Response> ? Response : unknown;

export type MutationOpts<Request, Response> = {
    mutate: APIMutateRequest<Request, Response>['mutate'];
    effect?: APIMutateEffect<Request, Response>;
} & (
    | {
          key: string;
      }
    | {
          get: APIGetRequest<Response>;
      }
    | {}
);
export function createMutation<Request, Response>(
    opts: MutationOpts<Request, Response>
): APIMutateRequest<Request, Response>;
export function createMutation<Request, Response>(
    mutate: APIMutateRequest<Request, Response>['mutate'],
    effect?: APIMutateEffect<Request, Response>
): APIMutateRequest<Request, Response>;
export function createMutation<Request, Response>(
    mutate: APIMutateRequest<Request, Response>['mutate'],
    get: APIGetRequest<Response>,
    effect?: APIMutateEffect<Request, Response>
): APIMutateRequest<Request, Response>;
export function createMutation<Request, Response>(
    mutate: APIMutateRequest<Request, Response>['mutate'],
    key: string,
    effect?: APIMutateEffect<Request, Response>
): APIMutateRequest<Request, Response>;
export function createMutation<Request, Response>(
    mutateOrOpts:
        | APIMutateRequest<Request, Response>['mutate']
        | MutationOpts<Request, Response>,
    getOrKeyOrEffect?:
        | APIGetRequest<Response>
        | string
        | APIMutateEffect<Request, Response>,
    effect?: APIMutateEffect<Request, Response>
): APIMutateRequest<Request, Response> {
    let mutate;
    let key: string | null = null;
    let get: APIGetRequest<Response> | null = null;
    if (typeof mutateOrOpts === 'function') {
        mutate = mutateOrOpts;
        if (typeof getOrKeyOrEffect === 'string') {
            key = getOrKeyOrEffect;
        } else if (typeof getOrKeyOrEffect === 'function') {
            effect = getOrKeyOrEffect;
        } else if (getOrKeyOrEffect !== undefined) {
            key = getOrKeyOrEffect.key;
            get = getOrKeyOrEffect;
        }
    } else {
        mutate = mutateOrOpts.mutate;
        effect = mutateOrOpts.effect;
        if ('key' in mutateOrOpts) {
            key = mutateOrOpts.key;
        } else if ('get' in mutateOrOpts) {
            get = mutateOrOpts.get;
            key = mutateOrOpts.get.key;
        }
    }
    return { mutate, get, key, effect: effect ?? function () {} };
}

export function fakeAxiosSuccess<T>(data: T): AxiosResponse<T> {
    return {
        data,
        config: {
            headers: new AxiosHeaders(),
        },
        headers: {},
        status: 200,
        statusText: 'success',
    };
}

export type GetAxiosConfiguration = Pick<
    AxiosRequestConfig,
    'onDownloadProgress' | 'signal'
>;
export type GetConfiguration = SWRConfiguration & GetAxiosConfiguration;

export function simpleGet<Data>(
    strParts: TemplateStringsArray,
    ...args: Array<URLSearchParams | string | undefined | null>
): APIGetRequest<{
    pagination: any;
    data: Data;
}> {
    return createGet(safeUrl(strParts, ...args));
}

export function createGet<Data>(
    key: string,
    get?: APIGetRequest<Data>['get'],
    swr?: SWRConfiguration<Data>
): APIGetRequest<Data> {
    return {
        key,
        get: (get ??
            (async ({ authenticated }, key, config?: GetAxiosConfiguration) => {
                const res = await authenticated.get(key, config);
                return res.data;
            })) as APIGetRequest<Data>['get'],
        swr,
        mutate: (data, opts) => mutateCache(key, data, opts),
    };
}

const annonymous = new Axios({
    baseURL: API_BASE_URL,
    transformResponse,
    transformRequest,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const cache = new WeakMap<AuthHeader, InternalApis>();
async function getApis(): Promise<InternalApis> {
    const header = await getAuthHeader();
    let apis = cache.get(header);
    if (apis) {
        return apis;
    }
    apis = {
        annonymous,
        authenticated: new Axios({
            baseURL: API_BASE_URL,
            transformResponse,
            transformRequest,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                ...header,
            },
        }),
    };
    cache.set(header, apis);
    return apis;
}

export interface InternalApis {
    annonymous: Axios;
    authenticated: Axios;
}

export const mutationRequest = async <Request, Response>(
    mutation: APIMutateRequest<Request, Response>,
    request: Request,
    options: MutationRequestOptions = {}
) => {
    const apis = await getApis();
    return await mutation.mutate(apis, request, options);
};

export interface MutationConfiguration {
    queue?: boolean;
    dropQueuedItem?: boolean;
    swr?: SWRConfiguration;
}
export interface MutationRequestOptions {
    onProgress?: ProgressHandler;
    signal?: AbortSignal;
}

interface QueueItem<Request> {
    request: Request;
    options?: MutationRequestOptions;
}

export interface UseMutationResponse<
    Mutation extends APIMutateRequest<any, any> | null,
> {
    data: Mutation extends APIMutateRequest<any, infer Response>
        ? Response | null
        : null;
    isDataFromSend: boolean;
    isLoading: boolean;
    isSending: boolean;
    lastSendSuccess?: number;
    error: Error | null;
    send: (
        request: Mutation extends APIMutateRequest<infer Request, any>
            ? Request
            : null,
        options?: MutationRequestOptions
    ) => void;
    cancel: () => void;
}
export const useMutation = <Request extends APIMutateRequest<any, any> | null>(
    mutation: Request,
    config?: Omit<MutationConfiguration, 'signal'>
): UseMutationResponse<Request> => {
    const controllerRef = useRef<AbortController>();
    const [data, setData] = useState<any>(null);
    const [lastSendSuccess, setLastSendSuccess] = useState<number>();
    const [isDataFromSend, setIsDataFromSend] = useState(false);
    const [isSending, setSending] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const mutationKey = useRef<string | null | undefined>();
    if (mutationKey.current !== mutation?.key) {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = undefined;
        mutationKey.current = mutation?.key;
        setLastSendSuccess(undefined);
        setIsDataFromSend(false);
        setSending(false);
        setError(null);
    }
    const queue = useMemo(() => {
        if (!config?.queue) return;
        return [] as QueueItem<Request>[];
    }, [config?.queue]);
    useEffect(
        () => () => {
            const controller = controllerRef.current;
            controllerRef.current = undefined;
            controller?.abort();
        },
        []
    );
    const loaded = useGet(mutation?.get ?? null, config?.swr);
    useEffect(() => {
        if (loaded.data && loaded.key) {
            setData(loaded.data);
            setIsDataFromSend(false);
        }
    }, [loaded.lastUpdate]);
    const send = useCallback(
        (request: Request, options?: MutationRequestOptions) => {
            if (queue && controllerRef.current) {
                const queueItem = { request, options };
                if (config?.dropQueuedItem) {
                    queue[0] = queueItem;
                } else {
                    queue.push(queueItem);
                }
                return;
            }
            if (!mutation) {
                setError(
                    new Error('Can not request anything without a mutation...')
                );
                return;
            }
            const key = mutation.key;
            const processOne = async ({
                request,
                options,
            }: QueueItem<Request>): Promise<MutationResponse<Response>> => {
                const apis = await getApis();
                return await mutation.mutate(apis, request, {
                    ...options,
                });
            };
            const process = !queue
                ? processOne
                : async (request: QueueItem<Request>, signal: AbortSignal) => {
                      let lastData: MutationResponse<Response>;
                      let maybeRequest: QueueItem<Request> | undefined =
                          request;
                      while (maybeRequest) {
                          checkAbort(signal);
                          lastData = await processOne(maybeRequest);
                          maybeRequest = queue.shift();
                      }
                      return lastData!;
                  };
            const controller = new AbortController();
            controllerRef.current = controller;
            setSending(true);
            process({ request, options }, controller.signal)
                .then((response) => {
                    if (key !== mutationKey.current) return;
                    setData(response.data);
                    setIsDataFromSend(true);
                    setError(null);
                    setLastSendSuccess(Date.now());
                    if (mutation.get) {
                        mutateCache(mutation.get.key, response);
                    }
                    return mutation.effect(response, request, mutation.key);
                })
                .catch((error) => {
                    if (key !== mutationKey.current) return;
                    setError(error);
                })
                .finally(() => {
                    if (key !== mutationKey.current) return;
                    controllerRef.current = undefined;
                    setSending(false);
                });
        },
        [mutation]
    );
    const cancel = useCallback(
        () => controllerRef.current?.abort(),
        [controllerRef]
    );
    return {
        data: mutation?.get ? loaded.data : data,
        lastSendSuccess,
        isDataFromSend,
        isLoading: loaded.isLoading,
        isSending,
        error,
        send,
        cancel,
    };
};

export type GetAsyncConfiguration = GetAxiosConfiguration & {
    signal?: AbortSignal;
};

export async function mutateAsync<Request, Response>(
    request: APIMutateRequest<Request, Response>,
    data: Request,
    options?: MutationRequestOptions
): Promise<MutationResponse<Response>> {
    const apis = await getApis();
    return await request.mutate(apis, data, options || {});
}

export async function getAsync<Request extends APIGetRequest<any> | null>(
    request: Request,
    config?: GetAsyncConfiguration
): Promise<Request extends APIGetRequest<infer Data> ? Data : null> {
    if (request === null) {
        // @ts-ignore
        return null;
    }
    const apis = await getApis();
    const key = request.key;
    const data = await request.get(apis, key, config ?? {});
    await mutateCache(key, data);
    return data;
}

export type LimitedMutator<Data> = (
    data?: undefined | Data | Promise<Data>,
    opts?: boolean | MutatorOptions<Data>
) => Promise<Data | undefined>;
export interface UseGetResponse<Data, Error>
    extends Omit<SWRResponse<Data, Error, SWRConfiguration<Data>>, 'mutate'> {
    lastUpdate: number;
    key?: string;
    mutate: LimitedMutator<Data>;
}

export type ResponseForRequest<T extends APIGetRequest<any> | null> =
    T extends APIGetRequest<infer Data> ? Data : undefined;

export async function mutateCache(): Promise<void>;
export async function mutateCache(key: string): Promise<any>;
export async function mutateCache<T>(
    key: string,
    data: T | undefined,
    opts?: boolean | MutatorOptions
): Promise<T | undefined>;
export async function mutateCache<T>(
    key?: string | null,
    data?: T | boolean | null,
    opts?: boolean | MutatorOptions
) {
    if (!key) {
        return mutate(() => true);
    }
    if (data && (key === undefined || key === null)) {
        return data;
    }
    return (
        await mutate(
            key,
            data
                ? {
                      lastUpdate: Date.now(),
                      data,
                      key,
                  }
                : null,
            opts
        )
    )?.data;
}

export const useGet = <
    Request extends APIGetRequest<any> | null,
    Config extends GetConfiguration,
    TError = Error,
>(
    request: Request,
    config?: Config
): UseGetResponse<ResponseForRequest<Request>, TError> => {
    const { onDownloadProgress, ...swr } = config ?? {};
    const swrData = useSWR(
        request?.key,
        async (key) => {
            if (!request) {
                return;
            }
            return await request
                .get(await getApis(), key, { onDownloadProgress })
                .then<{
                    lastUpdate: number;
                    key: string | undefined;
                    data: any;
                }>((data) => ({
                    lastUpdate: Date.now(),
                    key,
                    data,
                }));
        },
        request
            ? swr
                ? request.swr
                    ? Object.assign(swr, request.swr)
                    : swr
                : request.swr
            : {}
    );
    const mutate = useCallback<LimitedMutator<ResponseForRequest<Request>>>(
        async (data, opts) => {
            if (!request) {
                return;
            }
            const { key } = request;
            return (
                (await (key
                    ? opts
                        ? mutateCache(key, await data, opts)
                        : mutateCache(key, await data)
                    : mutateCache(
                          key,
                          await request.get(await getApis(), key, {
                              onDownloadProgress,
                          })
                      ))) ?? undefined
            );
        },
        [request?.key, onDownloadProgress]
    );
    return {
        ...swrData,
        data: swrData.data?.key === request?.key ? swrData.data?.data : null,
        lastUpdate: swrData.data?.lastUpdate ?? 0,
        mutate,
        key: request?.key,
    };
};
